import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { GlobalContextProvider } from "./GlobalContext"
import { AppBarTest } from "./pages/AppBarTestPage"
import { HomePage } from "./pages/HomePage"

export const App: React.FC = () => {
    return (
        <GlobalContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/AppBarTest" element={<AppBarTest />} />
                </Routes>
            </BrowserRouter>
        </GlobalContextProvider>
    )
}
