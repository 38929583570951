import React from "react"
import { DarkModeSwitch } from "react-toggle-dark-mode"

export const DarkModeSwitcher: React.FC = () => {
    const [darkMode, setDarkMode] = React.useState(localStorage.getItem("theme") == "dark")

    function toggleDarkMode(checked: boolean) {
        setDarkMode(checked)
        if (checked) {
            localStorage.setItem("theme", "dark")
            document.documentElement.classList.add("dark")
        } else {
            localStorage.setItem("theme", "light")
            document.documentElement.classList.remove("dark")
        }
    }

    return (
        <div>
            <DarkModeSwitch checked={darkMode} onChange={toggleDarkMode} size={52} />
            <p>{darkMode ? "Dark Theme" : "Light Theme"}</p>
        </div>
    )
}
