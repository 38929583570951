import React from "react"
import { useNavigate } from "react-router-dom"
import { ButtonPrimary } from "../components/Button"

export const AppBarTest: React.FC = () => {
    const navigate = useNavigate()
    return (
        <div className="h-screen overflow-hidden">
            <div className="absolute top-3 right-0">
                <ButtonPrimary text="< Back" onClick={() => navigate("/")} />
            </div>
            <div className="h-screen pb-[135px] ss:pl-24 ss:pb-[56px] overflow-hidden">
                <Content />
            </div>
            <div className="invisible ss:visible">
                <SideBar />
            </div>
            <div className="visible ss:invisible">
                <BottomBar />
            </div>
        </div>
    )
}

export const SideBar: React.FC = () => {
    const [hovered, setHovered] = React.useState(false)
    const [selected, setSelected] = React.useState("Nothing")

    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => {
                setHovered(false)
                setSelected("Nothing")
            }}
        >
            <div
                // Bug when hiding side bar when screen width gets too small
                className="absolute left-20 inset-0 bg-green-700 h-screen space-y-2 ease-in-out duration-300"
                style={{ width: hovered ? "5rem" : "0px", padding: hovered ? "0.5rem" : "0px" }}
            >
                {hovered && (
                    <>
                        {SIDE_BUTTONS.map((button) => (
                            <SideBarButton key={button.label} icon={button.icon} text={button.label} />
                        ))}
                        <div className="pt-2">{`selected = ${selected}`}</div>
                    </>
                )}
            </div>
            <div className="absolute inset-0 bg-green-500 h-screen w-20 p-2 space-y-2">
                {SIDE_BUTTONS.map((button) => (
                    <SideBarButton
                        key={button.label}
                        icon={button.icon}
                        text={button.label}
                        setSelected={setSelected}
                    />
                ))}
            </div>
        </div>
    )
}

export const BottomBar: React.FC = () => {
    // TODO hover, selected
    return (
        <div className="absolute inset-x-0 bottom-0 bg-purple-500 text-slate-900 w-screen h-20 p-2 space-x-2 justify-center flex">
            {BOTTOM_BUTTONS.map((button) => (
                <SideBarButton key={button.label} icon={button.icon} text={button.label} />
            ))}
        </div>
    )
}

export const SideBarButton: React.FC<{
    icon: string
    text: string
    setSelected?: (selected: string) => void
}> = ({ icon, text, setSelected }) => {
    return (
        <button
            type="button"
            onClick={() => alert(`Clicked = ${text}!`)}
            onMouseEnter={() => setSelected && setSelected(text)}
            className="block text-center text-slate-900 hover:bg-green-700 rounded-md h-16 w-16"
        >
            <span className="material-symbols-rounded text-3xl">{icon}</span>
            <div className="text-1xl font-semibold">{text}</div>
        </button>
    )
}

const SIDE_BUTTONS = [
    {
        icon: "home",
        label: "Home",
        path: "/",
    },
    {
        icon: "person",
        label: "Me",
        path: "/",
    },
    {
        icon: "music_note",
        label: "Songs",
        path: "/",
    },
    {
        icon: "library_music",
        label: "Library",
        path: "/",
    },
    {
        icon: "queue_music",
        label: "Playlist",
        path: "/",
    },
    {
        icon: "manage_search",
        label: "Search",
        path: "/",
    },
    {
        icon: "science",
        label: "Science",
        path: "/",
    },
]

const BOTTOM_BUTTONS = [
    {
        icon: "home",
        label: "Home",
        path: "/",
    },
    {
        icon: "person",
        label: "Me",
        path: "/",
    },
    {
        icon: "library_music",
        label: "Library",
        path: "/",
    },
    {
        icon: "manage_search",
        label: "Search",
        path: "/",
    },
    {
        icon: "science",
        label: "Science",
        path: "/",
    },
]

export const Content: React.FC = () => {
    return (
        <div className="h-full">
            <div className="p-2 w-full text-center">
                <h1 className="text-4xl">Cartographer</h1>
            </div>
            <div className="h-full overflow-auto">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi sapiente quisquam in, deserunt itaque
                quam! Dolores quis quas odio aut rerum cumque laborum in magnam, doloremque voluptates labore,
                consectetur deleniti eveniet! Quos harum asperiores quam. Sint tempora quas, saepe suscipit quod nostrum
                dolorem voluptatibus porro temporibus ab nobis qui sed quaerat. Cupiditate explicabo culpa dolorum
                ipsum. Modi cumque ut cum rerum omnis iure officia aliquam praesentium est? Inventore porro fuga vero ab
                nisi delectus culpa beatae reprehenderit exercitationem placeat labore, est consequuntur quis tempore
                aut, eos ipsam sit maxime tenetur aliquam sed provident. Quam nisi maxime, necessitatibus dignissimos
                ab, molestiae unde aliquid non incidunt libero mollitia ad. Quas at minus nesciunt. Sit, ducimus? Odio
                non libero in ipsum. Eligendi, facilis praesentium architecto unde dolores minus recusandae autem
                corrupti sunt, reiciendis iusto debitis eos, veritatis perferendis ipsa atque delectus voluptates. Vero
                magnam eaque obcaecati praesentium animi voluptatem quia expedita, recusandae iusto quaerat, odit
                accusantium ducimus reiciendis est at rerum inventore, eum blanditiis illum voluptate in debitis sed!
                Sapiente dolor quas sequi necessitatibus? Doloremque quos quibusdam, similique optio necessitatibus iste
                inventore cum quaerat veniam illum soluta placeat asperiores reiciendis ipsum nihil, rerum eius
                perspiciatis expedita id voluptates accusamus fugit odio. Harum voluptatibus qui fugiat voluptate a eum?
                Reiciendis ea iusto architecto, velit nisi facilis, temporibus, odio quam quia hic eveniet ullam? Nisi,
                cupiditate hic quos tempore iusto cumque doloremque corrupti et deserunt in similique. Aperiam
                temporibus illo corrupti eveniet animi vero! A odit at harum voluptatibus excepturi nulla id, vero eius
                expedita soluta. Accusamus delectus ex consequatur facere ipsam saepe veniam laborum, non voluptates?
                Quo aspernatur temporibus aperiam excepturi numquam, ea et nam fugit est vel. Suscipit quam quis nulla
                officiis cupiditate ipsa rem deleniti quisquam similique quod, ullam alias dolorum et animi error autem
                veritatis quasi. Iusto accusamus est perspiciatis saepe sint eligendi fugit voluptates quam quis modi.
                Voluptatibus, molestiae minus. Mollitia officiis iure natus amet ad aut dignissimos ut assumenda magnam
                libero sit ea quas a neque, fuga recusandae ipsa placeat dolorum commodi excepturi perspiciatis, sed
                dicta numquam. Corrupti quidem assumenda possimus vero fugiat veniam laudantium eum quis! Sequi, ipsam
                ratione quia rem ipsum aliquid voluptate maxime, dolore quas, enim aperiam corrupti similique ullam
                error ea rerum voluptates repellendus omnis qui distinctio laborum voluptas. Ipsa itaque ea facere
                possimus nihil corporis, necessitatibus animi doloremque totam cumque fuga praesentium nostrum tempore
                rerum assumenda sunt unde tempora maiores nesciunt debitis ipsum aliquam, excepturi eveniet doloribus.
                Vero, voluptates ipsa minima debitis voluptatibus atque ut aliquid nam fugit, earum animi. Illo suscipit
                quaerat unde deleniti quos fuga earum aperiam laboriosam nulla. Consequuntur magnam veniam fugit illo
                beatae vitae nulla quisquam perspiciatis nobis possimus, harum facere ipsam quasi numquam ad. Eos beatae
                eligendi laboriosam, quisquam facere amet ducimus magni natus officiis, soluta blanditiis repellendus
                reiciendis similique ratione, sapiente unde vero neque reprehenderit vel? Consectetur distinctio, magni
                sint modi soluta culpa eius veniam aliquid voluptatibus rem ipsum eveniet, ipsa possimus reprehenderit,
                laborum iste voluptate nihil quis id optio? Ut amet voluptas deleniti odio ea assumenda, animi, nisi
                quibusdam quos corrupti exercitationem culpa odit illum officia architecto voluptates maxime aperiam,
                quae recusandae esse eveniet perspiciatis! Sed natus aliquid, vero molestias odio fugiat autem
                praesentium eos perspiciatis dolorem a inventore cum quaerat voluptatibus dolorum, cupiditate
                consectetur magni illum omnis enim mollitia sint eaque eveniet. Fugiat molestias voluptate officiis
                possimus placeat facere blanditiis aperiam earum. Odit, reprehenderit iste. Recusandae beatae dolor
                porro. Rerum mollitia incidunt cumque explicabo placeat quasi labore optio veritatis accusantium nobis
                quia pariatur, obcaecati expedita quas sequi maiores at magnam nisi voluptates deserunt. Pariatur eos
                magni illum soluta, consectetur, natus voluptatibus, quos voluptatem in itaque rerum consequatur quasi.
                Commodi earum aperiam minus praesentium nobis, nam fugit cupiditate suscipit ut itaque eligendi
                repudiandae, necessitatibus sapiente provident, nostrum voluptatem aspernatur vel adipisci veritatis in
                modi cumque. Amet ducimus accusamus, voluptates tempora fugiat esse minima possimus cupiditate
                reiciendis corrupti odit quidem nemo ipsa exercitationem libero quasi asperiores aperiam cumque harum
                officiis, excepturi architecto rerum quas. Labore fugiat cum error harum, ipsam explicabo impedit
                voluptates est consequuntur nostrum libero iure voluptatibus! Reiciendis ipsam a ratione temporibus
                autem itaque voluptate hic perspiciatis, odio, dolorum quam cupiditate ut expedita mollitia. Nam iusto
                libero maxime deleniti illum voluptatem? Pariatur magnam ea voluptatibus iste assumenda nulla
                praesentium id autem aliquid explicabo, aliquam architecto laborum, unde consequatur ad quod quidem.
                Quas similique odio sint repellendus error quis consectetur explicabo quaerat sequi dolorum, eius labore
                porro obcaecati ad qui. Quam facere labore officia. Dolores quam ducimus cum corporis sit incidunt
                aspernatur debitis at adipisci nisi ad a veritatis, eaque architecto iste rem soluta aperiam obcaecati
                porro perspiciatis praesentium. Deserunt dolorum iste, commodi beatae molestiae eius unde sequi
                repellendus iure nisi odio laborum! Aut laboriosam necessitatibus eos quasi ducimus. Totam tenetur eaque
                quis temporibus aperiam laboriosam odit. Consequuntur nostrum velit nihil officiis quasi aspernatur eum
                delectus. Obcaecati voluptate accusantium eius nobis reprehenderit eligendi ipsum, dolor in autem quasi
                expedita, recusandae vero! Possimus asperiores autem a alias sit minima commodi excepturi id quod! Amet
                enim magni quisquam odio aut laudantium rem culpa iure, placeat facilis error fuga aliquid labore
                similique consectetur obcaecati omnis minus earum unde. Repellendus doloremque ab aut molestias dolore
                facere adipisci officia voluptatem rem praesentium provident consequatur est doloribus vitae, accusamus
                earum fugit! Mollitia sit ratione fugiat debitis iusto, velit itaque maiores, qui quas minus
                consequuntur vero iste nemo reprehenderit laudantium beatae a saepe voluptatibus minima, dignissimos
                recusandae. Iure ex cupiditate, dolores voluptatem vero temporibus alias neque cumque facere culpa
                facilis, in voluptatum est quaerat harum! Veniam, beatae odit id obcaecati incidunt earum voluptatem aut
                sunt labore aliquam soluta unde porro provident molestiae quam repellat enim ipsam quo tempora omnis,
                nam laborum nulla at? Dolore ullam aspernatur modi possimus incidunt. Et perspiciatis voluptatum eos
                illum, ducimus culpa necessitatibus consectetur optio vero nisi deserunt nostrum, quaerat quia ab
                dolorem, commodi ratione dolorum assumenda iste accusantium minima explicabo quos repellat? Repellat
                itaque architecto corrupti nemo voluptatum fugit vero repellendus assumenda non autem? Magnam quasi illo
                corrupti accusamus similique quos saepe id mollitia error nam! Consectetur, quam libero?
            </div>
        </div>
    )
}
