import { useCallback, useRef, useState } from "react"
import { HexColorPicker } from "react-colorful"
import { useClickOutside } from "./useClickOutside.js"

export const ColorPicker = ({ color, onChange, presetColors }) => {
    const popover = useRef()
    const [isOpen, toggle] = useState(false)

    const close = useCallback(() => toggle(false), [])
    useClickOutside(popover, close)

    return (
        <div className="relative">
            <div
                className={`w-10 h-10 rounded-full border-solid cursor-pointer shadow-md`}
                style={{ backgroundColor: color }}
                onClick={() => toggle(true)}
            />

            {isOpen && (
                <div className="absolute bottom-12 -left-3 rounded-lg bg-white" ref={popover}>
                    <HexColorPicker color={color} onChange={onChange} />
                    <div className="flex p-2 flex-wrap">
                        {presetColors.map((presetColor) => (
                            <button
                                key={presetColor}
                                className="h-5 w-5 m-1 border-none p-0 rounded-3xl cursor-pointer outline-none"
                                style={{ backgroundColor: presetColor }}
                                onClick={() => onChange(presetColor)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
