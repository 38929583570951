import React from "react"
import { useNavigate } from "react-router"
import { ThemeManager } from "../complexComponents/ThemeManager"
import { ButtonPrimary, ButtonSecondary } from "../components/Button"
import { DarkModeSwitcher } from "../components/DarkModeSwitcher"

export const HomePage: React.FC = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className="mt-10 w-full text-center overflow-hidden">
                <h1 className="text-4xl">Cartographer</h1>
            </div>
            <div className="flex justify-center h-30 w-30 mt-20">
                <ButtonPrimary text="App Bar Test" onClick={() => navigate("/AppBarTest")} />
            </div>
            <div className="flex justify-center mt-20">
                <DarkModeSwitcher />
            </div>
            <div className="flex justify-center h-30 w-30 mt-20">
                <ThemeManager />
            </div>
            <div className="flex justify-center items-center mt-20">
                <Buttons />
            </div>
        </div>
    )
}

const Buttons = () => {
    return (
        <div className="w-20">
            <h1 className="font-medium pl-4 p-1 m-4">Buttons</h1>
            <div className="flex justify-center space-x-2">
                <ButtonPrimary text="Primary" />
                <ButtonSecondary text="Secondary" />
            </div>
        </div>
    )
}
