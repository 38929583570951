import React, { useState } from "react"

const noop = () => ({})

interface Props {
    children: React.ReactNode
}

export interface GlobalStateContext {
    state: GlobalState
    setState: (state: GlobalState) => void
}

export interface GlobalState {
    color: {
        primary: string
        secondary: string
        tertiary: string
    }
}

const initialState: GlobalState = {
    color: {
        primary: "#ffb347", // Pastel Orange
        secondary: "#47ffb3",
        tertiary: "#b347ff",
    },
}

const GlobalStateProviderContext = React.createContext<GlobalStateContext>({
    state: initialState,
    setState: noop,
})

export function GlobalContextProvider({ children }: Props): JSX.Element {
    const [state, setState] = useState<GlobalState>(initialState)

    const value = { state: state, setState: setState }
    return <GlobalStateProviderContext.Provider value={value}>{children}</GlobalStateProviderContext.Provider>
}

export function useGlobalState(): GlobalStateContext {
    const context = React.useContext(GlobalStateProviderContext)
    if (context === undefined) {
        throw new Error("useGlobalState must be used within a GlobalStateContext")
    }
    return context
}
