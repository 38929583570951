import React from "react"
import { useGlobalState } from "../GlobalContext"
import { ColorPicker } from "../icons/colorPicker/ColorPicker"

export const ThemeManager: React.FC = (): JSX.Element => {
    const { state, setState } = useGlobalState()

    const presetColors = ["#6231af", "#db2777", "#f59e0b", "#2dd4bf", "#06b6d4", "#10b981"]

    return (
        <div className="bg-gray-100 rounded-2xl shadow-md">
            <h1 className="text-2xl pt-4 mb-5 text-center">Theme Picker</h1>
            <div className="flex p-3 gap-10">
                <div>
                    <h2 className="text-xl pb-5 text-center">Accent Colors</h2>
                    <ColorDescriptorPicker
                        color={state.color.primary}
                        colorName="Primary"
                        presetColors={presetColors}
                        setColor={(color: string) => {
                            const newColorState = state.color
                            newColorState.primary = color
                            setState({ ...state, color: newColorState })
                        }}
                    />
                    <ColorDescriptorPicker
                        color={state.color.secondary}
                        colorName="Secondary"
                        presetColors={presetColors}
                        setColor={(color: string) => {
                            const newColorState = state.color
                            newColorState.secondary = color
                            setState({ ...state, color: newColorState })
                        }}
                    />
                    <ColorDescriptorPicker
                        color={state.color.tertiary}
                        colorName="Tertiary"
                        presetColors={presetColors}
                        setColor={(color: string) => {
                            const newColorState = state.color
                            newColorState.tertiary = color
                            setState({ ...state, color: newColorState })
                        }}
                    />
                </div>
                <div>
                    <h2 className="text-xl pb-5 text-center">Neutral Colors</h2>
                    <ColorDescriptor colorName="Neutral 200" color="bg-neutral-200" />
                    <ColorDescriptor colorName="Neutral Variant 300" color="bg-neutral-300" />
                </div>
            </div>
        </div>
    )
}

export const ColorDescriptorPicker: React.FC<{
    color: string
    colorName: string
    presetColors: string[]
    setColor?: (color: string) => void
}> = ({ color, colorName, presetColors, setColor }): JSX.Element => {
    return (
        <div className="w-full m-2 flex items-center">
            <ColorPicker
                color={color}
                onChange={setColor ? setColor : () => alert("Cant change")}
                presetColors={presetColors}
            />
            <p className="pl-3">{colorName}</p>
        </div>
    )
}

export const ColorDescriptor: React.FC<{
    colorName: string
    color: string
}> = ({ colorName, color }): JSX.Element => {
    return (
        <div className="w-full m-2 flex items-center">
            <div className={`w-10 h-10 rounded-full border-solid cursor-pointer shadow-md ${color}`} />
            <p className="pl-3">{colorName}</p>
        </div>
    )
}
